import { userAgentType } from './index';

export function jumpVipPgae(from) {
  let value = from ? `?from=${from}` : '';
  if (/i(os|phone|pad)/i.test(navigator.userAgent)) {
    window.location.href = `yyjz://Vip/center${value}`;
  }
  if (/android/i.test(navigator.userAgent)) {
    window?.android?.goToActivity2('jz://com.caiyi.accounting/com.caiyi.accounting.jz.vip.VipCenterActivity' + value);
  }
}

export function jsClosePage() {
  window?.android?.jzClose();
  window?.ios?.jzClose();
}

// 跳转客户端路由
export function jsJumpRouter(url) {
  window?.ios?.jumpRoute(url);
  window?.android?.jumpRoute(url);
}

export function jsJumpGoldRecord() {
  const { isAndroid, isPhone } = userAgentType();
  let url = isAndroid ? 'jz://com.caiyi.accounting/com.caiyi.accounting.jz.tree.GoldDetailActivity' : 'yyjz://app/goldDetai';
  return jsJumpRouter(url);
}

export function jsJumpCoupon() { // 跳转优惠券
  const { isAndroid } = userAgentType();
  let url = isAndroid ? 'jz://com.caiyi.accounting/com.caiyi.accounting.jz.coupon.activity.CouponListActivity' : 'yyjz://app/coupon';
  return jsJumpRouter(url);
}

export function jsBriageEvent(data) { // 埋点事件
  data = data || {};
  data = JSON.stringify(data);
  const { isAndroid, isPhone } = userAgentType();
  if (isPhone && window.ios && window.ios.jsBriageEvent) return window.ios.jsBriageEvent(data);
  // eslint-disable-next-line max-len
  if (isAndroid && window.android && window.android.jsBriageEvent) return window.android.jsBriageEvent(data);
}
